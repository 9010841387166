import React from "react";
import logo from "../../assets/logo_white.png";
import styles from "./Header.module.scss";

function Header() {
  return (
    <div className={styles.header_container}>
      <a className={styles.logo_link} href={"/"}>
        <img src={logo} alt={"Logo Fondation UTT"} />
      </a>

      <a
        href={"https://fondation.utt.fr/"}
        target="_blank"
        rel={"noreferrer noopener"}
      >
        Aller au site de la Fondation UTT <span>⟶</span>
      </a>
    </div>
  );
}

export default Header;
