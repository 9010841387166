import React from "react";
import Footer from "../../Shared/Footer";
import Header from "../../Shared/Header";
import DonationForm from "./DonationForm/DonationForm";
import styles from "./Home.module.scss";
import Typical from "react-typical";
import YoutubeEmbed from "../../Shared/YoutubeEmbed";
import ProjectsGrid from "./ProjectsGrid/ProjectsGrid";

function Home() {
  return (
    <div className={styles.page_container}>
      <Header />
      <div className={styles.main_container}>
        <div className={styles.col_content}>
          <h1>
            Comme 2300 autres{" "}
            <Typical
              steps={[
                "alumni",
                3000,
                "parents",
                3000,
                "ami.e.s",
                3000,
                "partenaires",
                3000,
                "donateurs",
                3000,
              ]}
              loop={Infinity}
              wrapper="span"
            />
            <br />
            <span className={styles.higlight_title}>
              soutenez celles et ceux qui font avancer l'UTT
            </span>
          </h1>
          <ProjectsGrid />
        </div>
        <div className={styles.col_form}>
          <DonationForm
            initialMode={
              window.location.search?.includes("unique")
                ? "unique"
                : "recurring"
            }
          />
        </div>
      </div>
      <div className={`${styles.main_container} ${styles.about}`}>
        <h2>A propos de la Fondation UTT</h2>
        <p>Bienvenue sur la plateforme de don en ligne de la Fondation UTT.</p>
        <p>
          L’objectif de la Fondation UTT est de récolter des fonds pour les
          étudiants, la recherche et le territoire. Nos dix axes de financement
          sont présents pour guider vos dons selon vos sensibilités. Elle est au
          service du rayonnement de l’UTT et souhaite développer les
          partenariats existants avec les entreprises et en susciter de
          nouveaux.
          <br /> <br />
          La Fondation a pour objet de soutenir et développer les missions de
          l’UTT. A ce titre elle soutient plus particulièrement les
          enseignements, les travaux de recherche et les actions de
          communication relatives à la sécurité globale et à la maîtrise des
          risques entendus au sens le plus large. Elle peut engager des actions
          en vue d’aider l’UTT à améliorer son offre pédagogique, son offre de
          recherche développement, ses équipements et son attractivité au plan
          national et international.
        </p>
        <a href={"https://fondation.utt.fr/"} target="_blank" rel="noreferrer">
          <button className={styles.see_plus_button}>En savoir plus</button>
        </a>
        <div className={styles.youtube_thumbnails}>
          <YoutubeEmbed embedId={"Ye_tPX1KKAo"} />
          <YoutubeEmbed embedId={"SsyQej2vz6U"} />
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Home;
