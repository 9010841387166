import React, { useEffect, useState } from "react";
import styles from "../Home.module.scss";
import btnIcon from "../../../../assets/icon.png";
import ToggleButton from "../../../Shared/ToggleButton";

// redirection --> https://don.fondation.utt.fr/merci

// below payment links are legacy payment links
// new logic uses the same payment link for a given amount and the flechage is done directly in stripe checkout form
const targets = {
  GENERAL: {
    title: "Développement général de l'UTT",
    amount: {
      5: "https://buy.stripe.com/bIYfZGfdd0AwemQ6ot",
      10: "https://buy.stripe.com/3cscNue993MI5Qk3ci",
      20: "https://buy.stripe.com/dR67taghhdnia6A4gn",
      50: "https://buy.stripe.com/cN2eVCghherm3Ic4go",
      100: "https://buy.stripe.com/7sIbJqc113MIemQ5kt",
    },
  },
  CHANCE: {
    title: "Égalité des chances",
    amount: {
      5: "https://buy.stripe.com/14kaFm3uv9723IcaEE",
      10: "https://buy.stripe.com/4gwcNu4yzbfa6Uo8wx",
      20: "https://buy.stripe.com/6oE3cUc11ab63Ic5km",
      50: "https://buy.stripe.com/dR6bJqd5582Y7Ys8wz",
      100: "https://buy.stripe.com/6oEfZGghh0Aw3IcdQU",
    },
  },
  ENTREPRENEURSHIP: {
    title: "Entrepreneuriat étudiant",
    amount: {
      5: "https://buy.stripe.com/5kAcNu8OP0Aw2E800j",
      10: "https://buy.stripe.com/9AQ28Qghh6YUbaEfZf",
      20: "https://buy.stripe.com/eVa4gY4yzdniguY14m",
      50: "https://buy.stripe.com/fZedRy5CD3MI5Qk7sI",
      100: "https://buy.stripe.com/7sI8xed55dni1A43cr",
    },
  },
  ASSO: {
    title: "Vie associative",
    amount: {
      5: "https://buy.stripe.com/7sI28QaWXbfa0w0cNp",
      10: "https://buy.stripe.com/7sIcNu1mnerm7Ys5kW",
      20: "https://buy.stripe.com/dR6dRyfddab67YseVv",
      50: "https://buy.stripe.com/bIY4gY5CD5UQ1A47t2",
      100: "https://buy.stripe.com/cN25l28OPerma6A7t1",
    },
  },
  EUT: {
    title: "EUT+",
    amount: {
      5: "https://buy.stripe.com/3csaFm6GHdni3IcfZ8",
      10: "https://buy.stripe.com/cN2fZGd55ab60w0aEP",
      20: "https://buy.stripe.com/bIY14Me992IEa6A4gs",
      50: "https://buy.stripe.com/28o8xe8OP4QMdiMfZb",
      100: "https://buy.stripe.com/bIY28Q9STdni3Ic28m",
    },
  },
  RESEARCH: {
    title: "Recherche - Transfert - École Doctorale",
    amount: {
      5: "https://buy.stripe.com/bIY9Bifdd2IE0w05kR",
      10: "https://buy.stripe.com/4gw7taaWX5UQdiMcNk",
      20: "https://buy.stripe.com/3csfZGaWX5UQfqUbJe",
      50: "https://buy.stripe.com/6oE4gYd554QM6Uo28D",
      100: "https://buy.stripe.com/eVa3cU2qrermfqU8x0",
    },
  },
  AFRICA: {
    title: "Talents Africains",
    amount: {
      5: "https://buy.stripe.com/cN2dRy5CD6YUguY5kF",
      10: "https://buy.stripe.com/4gw14Me99bfa5Qk3cA",
      20: "https://buy.stripe.com/fZe14M6GH82Y4MgdRd",
      50: "https://buy.stripe.com/bIY5l21mn2IEfqU28u",
      100: "https://buy.stripe.com/eVacNuc1182Y6Uo5kE",
    },
  },
  INTERNATIONAL: {
    title: "Mobilité internationale",
    amount: {
      5: "https://buy.stripe.com/14k28Q8OPdnidiMaFm",
      10: "https://buy.stripe.com/eVacNu8OP9726UodRx",
      20: "https://buy.stripe.com/bIYdRy4yz4QM3Ic5l0",
      50: "https://buy.stripe.com/9AQbJq2qrbfaceI5kZ",
      100: "https://buy.stripe.com/fZe7ta7KL9720w0fZC",
    },
  },
  SUSTAINABILITY: {
    title: "Sociétés durables",
    amount: {
      5: "https://buy.stripe.com/6oEaFm4yzbfaa6A00T",
      10: "https://buy.stripe.com/dR6cNu2qr97292waFy",
      20: "https://buy.stripe.com/bIYcNughh5UQfqUeVP",
      50: "https://buy.stripe.com/4gw8xeghhgzua6A4hc",
      100: "https://buy.stripe.com/dR6dRy6GH6YU3Ic8xt",
    },
  },
  // SOLIDARITY: {
  //   title: "Actions solidaires et éducatives",
  //   amount: {
  //     5: "https://buy.stripe.com/cN228Qfdd6YU5Qk8xo",
  //     10: "https://buy.stripe.com/00g4gYd55gzuguY14V",
  //     20: "https://buy.stripe.com/7sI14M6GH3MIemQ3d2",
  //     50: "https://buy.stripe.com/7sI8xe9STfvqguYbJx",
  //     100: "https://buy.stripe.com/00gbJq2qr9723IcbJw",
  //   },
  // },
  TERRITORY: {
    title: "Actions territoriales",
    amount: {
      5: "https://buy.stripe.com/00geVCe991EAfqU4h3",
      10: "https://buy.stripe.com/cN24gY9ST3MIemQ14Q",
      20: "https://buy.stripe.com/aEU00Ic1182Y3Ic3cX",
      50: "https://buy.stripe.com/cN24gY6GH5UQceI8xg",
      100: "https://buy.stripe.com/dR63cUd5582YbaEdRz",
    },
  },
  PEDAGOGY: {
    title: "Innovation Pédagogique et vie de campus",
    amount: {
      5: "https://buy.stripe.com/aEUdRy7KL9720w014x",
      10: "https://buy.stripe.com/28oeVCd555UQ7YsbJ9",
      20: "https://buy.stripe.com/7sI6p67KL6YUbaE8wY",
      50: "https://buy.stripe.com/fZe3cU4yz3MI0w04gG",
      100: "https://buy.stripe.com/bIYbJqghhfvq5Qk8wV",
    },
  },
};

// New logic

const recurringAmounts = [5, 10, 20, 50, 100];
const uniqueAmounts = [100, 200, 500, 1000, "Autre"];

const recurringPaymentLinks = {
  5: "https://buy.stripe.com/9AQ14Mc1182Y7Ys4hk",
  10: "https://buy.stripe.com/eVa9Bi0ij4QM6UoeVZ",
  20: "https://buy.stripe.com/14k00I1mnab65QkeW0",
  50: "https://buy.stripe.com/cN26p67KL5UQ6Uo15b",
  100: "https://buy.stripe.com/7sIbJq3uv9726Uo15c",
};
const uniquePaymentLinks = {
  100: "https://donate.stripe.com/aEU3cUd55cje2E83db",
  200: "https://donate.stripe.com/aEU28QaWXgzuguY4hg",
  500: "https://donate.stripe.com/eVaeVC9ST2IE4Mg9BB",
  1000: "https://donate.stripe.com/7sI28Q4yzab692waFG",
  Autre: "https://donate.stripe.com/aEUfZGaWX5UQ92wdRT",
};

const amounts = {
  recurring: recurringAmounts,
  unique: uniqueAmounts,
};

const paymentLinks = {
  recurring: recurringPaymentLinks,
  unique: uniquePaymentLinks,
};

function DonationForm({ initialMode }) {
  console.log(initialMode);
  const [mode, setSelectedMode] = useState(initialMode);
  const [selectedAmount, setSelectedAmount] = useState(amounts[mode][1]);

  useEffect(() => {
    setSelectedAmount(amounts[mode][1]);
  }, [mode]);

  const proceedToCheckout = () => {
    window.location.href = paymentLinks[mode][selectedAmount];
  };

  const getTotalAmount = (amount) => {
    if (mode === "unique") return amount;
    return Math.round(amount * 12);
  };

  const getTotalAmountAfterReduction = (amount) => {
    if (mode === "unique") return Math.round(amount * 0.33);
    return Math.round(amount * 12 * 0.33);
  };

  return (
    <div className={styles.donation_form_container}>
      <div className={styles.title_container}>
        <h2>Je verse</h2>
        <ToggleButton mode={mode} setSelectedMode={setSelectedMode} />
      </div>

      <div className={styles.tags_container}>
        {amounts[mode].map((amount, index) => {
          return (
            <div
              className={`${styles.amount_tag} ${
                selectedAmount === amount ? styles.selected_amount : ""
              }`}
              key={index}
              onClick={() => setSelectedAmount(amount)}
            >
              <span className={styles.monthly_amount}>
                {amount}
                {amount !== "Autre" && "€"}
              </span>
              <span>
                {mode === "recurring" && selectedAmount !== "Autre"
                  ? "par mois"
                  : "en une fois"}
              </span>
            </div>
          );
        })}
      </div>
      {/* {mode === "unique" && (
        <a href={"/"}>Je préfère effectuer un don récurrent</a>
      )}
      {mode === "recurring" && (
        <a href={"/don-unique"}>Je préfère effectuer un don unique</a>
      )} */}
      {selectedAmount !== "Autre" ? (
        <p className={styles.helper}>
          Vous donnez {getTotalAmount(selectedAmount)}€
          {mode === "recurring" && " par an"}, mais cela vous coûte en réalité{" "}
          <span className={styles.real_cost}>
            {getTotalAmountAfterReduction(selectedAmount)}€
          </span>{" "}
          après{" "}
          <a
            href="https://www.impots.gouv.fr/portail/particulier/questions/jai-fait-des-dons-une-association-que-puis-je-deduire"
            target={"_blank"}
            rel={"noreferrer noopener"}
          >
            déduction d'impôts
          </a>
          .
        </p>
      ) : (
        <p>Vous pourrez choisir le montant à l'étape d'après.</p>
      )}

      <p>
        Vous pourrez choisir le fléchage de votre don à l'étape d'après, parmi:
      </p>
      <div className={styles.tags_container}>
        {Object.entries(targets).map((target, index) => {
          return (
            <span key={index} className={styles.targetV2}>
              {target[1].title}
            </span>
          );
        })}
      </div>
      {/* <h2>Pour</h2>
      <div className={styles.tags_container}>
        {Object.entries(targets).map((target, index) => {
          return (
            <div
              className={`${styles.target_tag}  ${
                selectedTarget.title === target[1].title
                  ? styles.selected_target
                  : ""
              }`}
              key={index}
              onClick={() => setSelectedTarget(target[1])}
            >
              <span>{target[1].title}</span>
            </div>
          );
        })}
      </div> */}
      <button
        className={styles.confirm_button}
        onClick={() => proceedToCheckout()}
      >
        Je confirme mon don <img src={btnIcon} alt={"Icône don"} />
      </button>
      <p>
        Après confirmation de votre don, la Fondation UTT vous fera parvenir un
        reçu fiscal. Pour toute question ou pour mettre fin à votre don
        récurrent, vous pouvez nous contacter à{" "}
        <a
          href="mailto:fondation.utt@utt.fr"
          target={"_blank"}
          rel={"noreferrer noopener"}
        >
          fondation.utt@utt.fr
        </a>
      </p>
    </div>
  );
}

export default DonationForm;
