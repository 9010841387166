import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Components/Pages/Home/Home";
import Legal from "./Components/Pages/Legal/Legal";
import ThankYou from "./Components/Pages/ThankYou/ThankYou";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/merci" element={<ThankYou />} />
        <Route path="/mentions-legales" element={<Legal />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
