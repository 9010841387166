import React from "react";
import styles from "../Home.module.scss";

import rechercheImg from "../../../../assets/recherche.jpg";
import futuraufemininImg from "../../../../assets/futuraufeminin.png";
import machineProtoImg from "../../../../assets/machineproto.jpg";
import incroyabletalentImg from "../../../../assets/incroyabletalent.png";
import secuttImg from "../../../../assets/secutt.jpg";
import uttarenaImg from "../../../../assets/uttarena.jpg";
import innoImg from "../../../../assets/24h.jpg";
import cle4gImg from "../../../../assets/cle4g.jpg";
import mt80sImg from "../../../../assets/gael.jpg";
import crunchImg from "../../../../assets/crunch.jpg";
import mindtechImg from "../../../../assets/mindtech.jpg";
import togoImg from "../../../../assets/TOGO.png";
import eloquenceImg from "../../../../assets/eloquence.png";
import startupImg from "../../../../assets/tom.jpg";
import eutImg from "../../../../assets/eut.jpeg";
import cordeesImg from "../../../../assets/thomas.jpeg";
import ecodesignImg from "../../../../assets/ecodesign.jpg";
import moonMarsImg from "../../../../assets/moonmars.jpg";

const projects = [
  {
    tags: "#concours #culture",
    title:
      "Je soutiens Cynthia et Mohamad et les participants du concours d’éloquence de l’UTT.",
    img: eloquenceImg,
  },
  {
    tags: "#makers #diy",
    title:
      "Je finance de nouvelles machines au MIND Tech, l'atelier collaboratif de 130m2 dédié aux étudiants",
    img: mindtechImg,
  },
  {
    tags: "#territoire #innovation",
    title:
      "Je permets à +1000 étudiants de proposer des solutions innovantes aux problématiques d'entreprises locales, lors du Crunch Time",
    img: crunchImg,
  },
  {
    tags: "#startup #entrepreneuriat",
    title:
      "Je soutiens de jeunes entrepreneurs comme Maxime, Zarela, Marc-Antoine ou Tom qui lancent leur startup.",
    img: startupImg,
  },
  {
    tags: "#concours #innovation",
    title:
      "J'encourage Pauline et son équipe ainsi que de +100 participants aux 24h de l'innovation.",
    img: innoImg,
  },
  {
    tags: "#afrique #innovation",
    title:
      "Je contribue au projet MIND Togo 2021 qui vise à réaliser un motoculteur pour les habitants de Kpékpéta.",
    img: togoImg,
  },
  {
    tags: "#spatial #recherche",
    title:
      "Je permets à Lucas d'effectuer une mission d'exploration 'lunaire' à Hawai avec la NASA et l'ESA.",
    img: moonMarsImg,
  },
  {
    tags: "#covid #solidarité",
    title:
      "Je permets l'achat de 200 clés 4G pour que chaque étudiant puisse suivre les cours en distanciel avec la Covid-19.",
    img: cle4gImg,
  },
  {
    tags: "#écologie #makers",
    title:
      "Je rends possible l'achat de matériel de recyclage des plastiques à l'UTT.",
    img: machineProtoImg,
  },
  {
    tags: "#recherche #concours",
    title:
      "J'aide Gael et les 7 doctorants de l’UTT qui participant au concours Ma Thèse en 180 secondes.",
    img: mt80sImg,
  },
  {
    tags: "#concours #innovation",
    title:
      "Je soutiens les 20 étudiants IMEDD qui ont participé à l'EcoDesign Challenge à la Technopôle de l'Aube.",
    img: ecodesignImg,
  },
  {
    tags: "#recherche",
    title: "Je soutiens la recherche et les +200 doctorants de l'UTT.",
    img: rechercheImg,
  },
  {
    tags: "#développement #europe",
    title:
      "J'aide l'UTT à donner vie à l'Université de Technologie Européenne (EUT+) qui formera 100.000 étudiants dans 7 pays.",
    img: eutImg,
  },
  {
    tags: "#égalitédeschances #territoire",
    title:
      "Je permets à Thomas et 25 étudiants de partager leur expérience dans les collèges et lycées de Troyes, Nogent et le Haute Marne.",
    img: cordeesImg,
  },
  {
    tags: "#esport #associations",
    title:
      "J'aide l'UNG à organiser l'UTT Arena, le rendez-vous e-sport qui réunit +450 joueurs à Troyes.",
    img: uttarenaImg,
  },
  {
    tags: "#secourisme #associations",
    title:
      "Je soutiens l'achat d'équipements pour SecUTT, l'association de secourisme.",
    img: secuttImg,
  },
  {
    tags: "#associations",
    title: "Je soutiens les étudiants qui ont un incroyable talent!",
    img: incroyabletalentImg,
  },
  {
    tags: "#associations",
    title:
      "Je permets la lutte contre la précarité menstruelle grâces à des distributeurs.",
    img: futuraufemininImg,
  },
];

const get9RandomProjects = () => {
  return projects.sort((a, b) => 0.5 - Math.random()).slice(0, 9);
};

export default function ProjectsGrid() {
  const [projectsToShow, setProjectsToShow] = React.useState(
    get9RandomProjects()
  );

  React.useEffect(() => {
    const rotateItvl = setInterval(() => {
      setProjectsToShow(get9RandomProjects());
    }, 5000);
    return () => clearInterval(rotateItvl);
  }, []);

  return (
    <div className={styles.grid_container}>
      {projectsToShow.map((el, index) => {
        return (
          <div
            className={styles.grid_item}
            key={index}
            style={{
              backgroundImage: `url(${el.img})`,
            }}
          >
            <span className={styles.tags}>{el.tags}</span>
            <p>{el.title}</p>
            <div className={styles.overlay} />
          </div>
        );
      })}
    </div>
  );
}
