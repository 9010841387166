import React from "react";
import styles from "./ToggleButton.module.scss";

function ToggleButton({ mode, setSelectedMode }) {
  const onChange = (e) => {
    if (e.target.checked) {
      // don unique
      setSelectedMode("unique");
    } else {
      // don récurrent
      setSelectedMode("recurring");
    }
  };
  return (
    <div className={styles.toggleContainer}>
      <span className={mode === "recurring" ? styles.selected : undefined}>
        chaque mois
      </span>
      <label className={styles.switch}>
        <input
          defaultChecked={mode === "unique"}
          onChange={onChange}
          className={styles.input}
          type="checkbox"
        />
        <span className={styles.slider}></span>
      </label>
      <span className={mode === "unique" ? styles.selected : undefined}>
        en une fois
      </span>
    </div>
  );
}

export default ToggleButton;
