import React from "react";
import Header from "../../Shared/Header";
import styles from "./Legal.module.scss";

function Legal() {
  return (
    <div className={styles.page_container}>
      <Header />
      <div className={styles.main_container}>
        <h1>Mentions légales</h1>
        <p>
          Lorsque vous faites un don, la Fondation UTT détient des données à
          caractère personnel vous concernant. Conformément à la Loi
          Informatique, fichiers et libertés du 6 janvier 1978, vous disposez
          d'un droit d'accès, de rectification, de modification et d'opposition
          pour motifs légitimes concernant vos données. Vous pouvez exercer ce
          droit en vous adressant à la Fondation UTT. Les informations que vous
          communiquez sont destinées à la Fondation UTT. Les noms, prénoms et
          adresses électroniques des participants à l'opération
          "adhérent-donateur" de l'UTT seront transmises à l'ASANUTT.
          <br />
          <br />
          Le versement des dons effectués sur cette plateforme sont
          exclusivement operés par carte bancaire via une plateforme de paiement
          sécurisé : Stripe (www.stripe.com/fr)
          <br />
          <br />
          Pour modifier ou annuler un don effectué grâce à cette plateforme, il
          vous suffit d'envoyer un email à{" "}
          <a href={"mailto:fondation.utt@utt.fr"}>fondation.utt@utt.fr</a>.
          <br />
          <br />
          Pour plus d'informations sur la politique de confidentialité, la
          protection des données ainsi que les conditions générales liées aux
          dons, merci de vous réferer au site officiel de la Fondation UTT :
        </p>
        <ul>
          <li>
            <a href={"https://fondation.utt.fr/donnees-personnelles-cookies"}>
              Données personnelles &amp; Cookies
            </a>
          </li>
          <li>
            <a href={"https://fondation.utt.fr/mentions-legales"}>
              Mentions légales
            </a>
          </li>
        </ul>
        <h2>Conception, développement et intégration</h2>
        <p>Les alumni bénévoles 🤎</p>
        <h2>Hébergement</h2>
        <p>
          Google Cloud Platform - Google LLC , sise au 1600 Amphitheatre
          Parkway, MOUNTAIN VIEW CA 94043
        </p>
        <h2>Crédits photos</h2>
        <p>
          Service communication UTT, Le bonheur des gens – Photographes, Grace
          Chiang, Sylbain Bordier, Carole Bell, Alban Dumouilla, Philippe
          Lemoine, Argentique, Jean-Pierre Gilson, Danièle Nguyen Duc Long,
          Istock, Fotolia, BASF, Freepik.
        </p>
      </div>
    </div>
  );
}

export default Legal;
