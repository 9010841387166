import React from "react";
import styles from "./Footer.module.scss";

import fbIcon from "../../assets/picto_rs_01_facebook_blanc.png";
import twIcon from "../../assets/picto_rs_02_twitter_blanc.png";
import ytIcon from "../../assets/picto_rs_03_youtube_blanc.png";
import igIcon from "../../assets/picto_rs_04_instagram_blanc.png";
import liIcon from "../../assets/linkedin.png";

function Footer() {
  return (
    <div className={styles.footer_container}>
      <div>
        <h2>Liens utiles</h2>
        <a href={"/mentions-legales"}>Mentions légales</a>
      </div>
      <div>
        <h2>Réseaux sociaux</h2>
        {[
          {
            icon: fbIcon,
            url: "https://fondation.utt.fr/facebook",
          },
          {
            icon: twIcon,
            url: "https://fondation.utt.fr/twitter",
          },
          {
            icon: ytIcon,
            url: "https://fondation.utt.fr/youtube",
          },
          {
            icon: igIcon,
            url: "https://fondation.utt.fr/instagram",
          },
          {
            icon: liIcon,
            url: "https://fondation.utt.fr/linkedin",
          },
        ].map((link, index) => (
          <a
            key={index}
            href={link.url}
            className={styles.icon}
            target="_blank"
            rel={"noreferrer noopener"}
          >
            <img src={link.icon} alt={link.url} />
          </a>
        ))}
      </div>
      <div>
        <h2>Nous contacter</h2>
        <p>
          Pour toutes questions relatives à la Fondation UTT, une seule adresse
          :{" "}
          <a
            href={"mailto:fondation.utt@utt.fr"}
            style={{ textDecoration: "underline" }}
          >
            fondation.utt@utt.fr
          </a>
          <br />
          <br />
          Fondation de l'UTT
          <br />
          12 rue Marie Curie
          <br />
          CS 42060
          <br />
          10004 TROYES CEDEX
        </p>
      </div>
    </div>
  );
}

export default Footer;
