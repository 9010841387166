import React from "react";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import Header from "../../Shared/Header";
import styles from "./ThankYou.module.scss";
import philippeAdnot from "../../../assets/adnot.png";
import Footer from "../../Shared/Footer";

const HEADLINE = "Je suis donateur, je suis acteur.";
const BODY =
  "Comme 2300 alumi, parents ou ami.e.s, je soutiens celles et ceux qui font avancer l'UTT. Et vous?";
const URL = "https://don.fondation.utt.fr";

function ThankYou() {
  return (
    <div className={styles.page_container}>
      <Header />
      <div className={styles.main_container}>
        <h1>Merci beaucoup !</h1>
        <p>
          <b>
            Être donateur, c'est être acteur. Partagez le message autour de vous
            !
          </b>
        </p>
        <div className={styles.share_container}>
          <EmailShareButton subject={HEADLINE} body={BODY} url={URL}>
            <EmailIcon size={32} round={true} />
          </EmailShareButton>
          <FacebookShareButton quote={HEADLINE + " " + BODY} url={URL}>
            <FacebookIcon size={32} round={true} />
          </FacebookShareButton>
          <TwitterShareButton title={HEADLINE + " " + BODY} url={URL}>
            <TwitterIcon size={32} round={true} />
          </TwitterShareButton>
          <LinkedinShareButton
            title={HEADLINE}
            summary={BODY}
            source={URL}
            url={URL}
          >
            <LinkedinIcon size={32} round={true} />
          </LinkedinShareButton>
          <WhatsappShareButton title={HEADLINE + " " + BODY} url={URL}>
            <WhatsappIcon size={32} round={true} />
          </WhatsappShareButton>
        </div>
        <div>
          <p>
            Au nom de l’UTT, je vous remercie chaleureusement pour votre soutien
            à notre défi commun : investir dans la valeur du diplôme UTT, afin
            de concurrencer les meilleures écoles d’ingénieurs mondiales.
            <br />
            <br />
            Vous recevrez prochainement votre reçu fiscal par courrier et serez
            tenu au courant des avancées de l’UTT par l’envoi d’une lettre
            d’information ainsi que par des invitations à ses évènements. <br />
            <br />
            En espérant avoir le plaisir de vous rencontrer prochainement, je
            vous prie de recevoir l'expression de mes meilleures salutations.{" "}
            <br />
          </p>
          <div className={styles.picture_container}>
            <img
              src={philippeAdnot}
              className={styles.picture}
              alt={"Philippe Adnot"}
            />
            <p>
              <b> Philippe Adnot, Président de la Fondation UTT</b>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ThankYou;
